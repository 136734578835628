@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  width: 180px;
  height: 40px;
  border: none;
  color: #ffffff54;
  border-radius: 10px;
  background-color: #9d9d9d4f;
  transition: 0.3s;
}

button:hover {
  cursor: pointer;
  background-color: #9d9d9d6b;
}

input[type="text"] {
  background-color: #ffffff1a;
  border: none;
  border-radius: 10px;
  padding: 20px;
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
  transition: 0.2s;
  caret-color: white;
}

input:not(:placeholder-shown) {
  color: white;
}

input[type="text"]:focus {
  border: none;
  outline: none;
  background-color: #ffffff1f;
}

input[type="text"]:hover {
  background-color: #ffffff1f;
}

.wrapper {
  position: flex;
}

.icon {
  height: 2rem;
  width: 2rem;
  font-size: 1.5rem;
  color: #ffffff5b;
  padding: 4px;
  position: absolute;
  box-sizing:border-box;
  top:35%;
  left:31.5%;
  transform: translateY(-50%);
}

.input {
  height: 60px;
  padding: 10px;
  box-sizing:border-box;
}

.icon-btn {
  width: 40px;
  background: none;
}

.icon-btn:hover {
  background: none;
  color: white;
  transform: scale(1.1);
}