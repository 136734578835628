.module-card {
    width: 430px;
    height: 155px;
    margin: 0;
    cursor: default;
    border-radius: 10px;
    transition: 0.3s;
    background: url('../../images/mod-background.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.module-card:hover {
    background-color: rgba(78, 75, 75, 0.092);
}

.module-card-head {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: space-between;
}

.module-card-title {
    margin-left: 20px;
    font-size: 20px;
    font-weight: 600;
}

.module-card-logo {
    width: 25px;
    height: 25px;
    background: url('../../images/cube.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 20px;
}

.module-card-body {
    margin-top: 15px;
}

.module-card-btn-group {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-top: 13px;
    margin-left: 20px;
    margin-right: 20px;
    gap: 20px;
}

.module-card-enabled-btn {
    background-color: #6d85ff46;
}

.module-card-enabled-btn:hover {
    background-color: #6d85ff65;
}

.module-card-description {
    margin-left: 20px;
}