.setting-wrap {
    display: grid;
    row-gap: 15px;
}

.setting-component {
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.089);
    margin-right: 20px;
    font-weight: 700;
    font-size: 20px;
    border-radius: 10px;
    padding: 10px;
    padding-left: 10px;
    align-items: center;
}

.setting-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    margin-bottom: 20px;
    height: 40px;
}

.setting-name {
    margin-left: 10px;
}

input[type=checkbox].switch {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 3em;
    height: 1.5em;
    border-radius: 4em;
    background-color: #747474;
    border: none;
    cursor: pointer;
    transition: 400ms;
    position: relative;
}

input[type=checkbox].switch:checked {
    background-color: #344c6b;
}

input[type=checkbox].switch::after {
    content: '';
    width: 1.5em;
    height: 1.5em;
    background-color: white;
    border-radius: 3em;
    border: none;
    position: absolute;
    transform: scale(0.7);
    left: 0;
    transition: 400ms;
}

input[type=checkbox].switch:checked::after {
    left: 1.5em;
}

.slider {
    width: 500px;
    border: none;
}

input[type="range"] {
    -webkit-appearance: none;
    background: rgba(41, 41, 41, 0.6);
    border-radius: 5px;
    background-repeat: no-repeat;
    border-radius: 20px;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border: none;
    border-radius: 50%;
    background: #1353a7;
    transition: 300ms;
}

input[type="range"]::-webkit-slider-thumb:hover {    
    background: #1764c9;
}

input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}