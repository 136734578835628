.window {
    background: rgb(0,0,0);
    height: 67vh;
    border-radius: 10px;
    width: 120vh;
    position: absolute;
    top: 16.5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
}

.tabs {
    margin-left: 20px;
    width: 360px;
}

.content {
    margin-top: 20px;
    margin-left: 20px;
    width: 100%;
}

.tab {
    cursor: pointer;
    transition: 300ms;
    opacity: 0.8;
    background-color: rgba(255, 255, 255, 0.068);
    border-radius: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    padding: 20px;
}

.tab:hover {
    background-color: rgba(255, 255, 255, 0.108);
}

.tab-wrapper {
    margin-top: 110px;
    height: 85%;
}