.module-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
}

.module-search-bar {
    height: 10px;
    margin-bottom: 20px;
}

.titlebar {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    font-size: 20px;
    margin-bottom: 25px;
    height: 50px;
}

.titlebar-title {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
}